/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');

/* Root variables */
:root {
    --primary-color: #FF0000;
    --secondary-color: #FF4081;
    --accent-color: #82B1FF;
    --background-color: #FFFFFF;
    --surface-color: #FFFFFF;
    --text-color: #333333;
    --text-light-color: #666666;
    --drawer-bg: #333333;
    --drawer-text: #FFFFFF;
    --drawer-width: 256px;
    --app-bar-height: 64px;
    --card-bg: #FFFFFF;
    --hover-bg: #F5F5F5;
    --border-color: #E0E0E0;
    --error-color: #dc3545;
    --success-color: #28a745;
    --warning-color: #ffc107;
    --border-radius: 8px;
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.1);
    --transition-speed: 0.3s;
}

/* CSS Reset */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Base Styles */
body {
    margin: 0;
    font-family: 'Cardo', serif;
    background: radial-gradient(circle at center, #FFFFFF 0%, #F8F9FA 35%, #E9ECEF 100%);
    background-attachment: fixed;
    color: var(--text-color);
    line-height: 1.5;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    background: transparent;
}

/* Layout */
.app-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    background: transparent;
    position: relative;
    z-index: 1;
}

.main-content {
    flex: 1;
    max-width: 100vw;
    overflow-x: hidden;
    padding: 2rem 1rem;
    margin-top: var(--app-bar-height);
    overflow-y: auto;
    height: calc(100vh - var(--app-bar-height));
    background: transparent;
}

/* Navigation */
.main-nav {
    width: 100%;
}

.app-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--app-bar-height);
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 1000;
    transition: left 0.3s ease;
}

.menu-button {
    display: flex;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.menu-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.app-title {
    font-family: 'Cardo', serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    flex-grow: 1;
}

.navigation-drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: var(--drawer-width);
    background-color: var(--drawer-bg);
    transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.navigation-drawer.open {
    transform: translateX(0);
}

.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 999;
}

.drawer-overlay.open {
    opacity: 1;
    visibility: visible;
}

/* Drawer Header */
.drawer-header {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    min-height: var(--app-bar-height);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.drawer-title {
    color: var(--drawer-text);
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: 'Cardo', serif;
}

/* Navigation List */
.nav-list {
    padding: 1rem 0;
    overflow-y: auto;
}

.nav-item {
    display: flex;
    align-items: center;
    padding: 0.875rem 1.5rem;
    color: var(--drawer-text);
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.nav-item.active {
    background-color: rgba(255, 0, 0, 0.15);
}

.nav-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: var(--primary-color);
}

.nav-icon {
    margin-right: 1rem;
    font-size: 1.5rem;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.2s ease;
}

.nav-item:hover .nav-icon,
.nav-item.active .nav-icon {
    color: var(--primary-color);
}

/* Utility Classes */
.text-center { text-align: center; }
.mt-1 { margin-top: 1rem; }
.mb-1 { margin-bottom: 1rem; }
.ml-1 { margin-left: 1rem; }
.mr-1 { margin-right: 1rem; }
.p-1 { padding: 1rem; }
.flex { display: flex; }
.items-center { align-items: center; }
.justify-between { justify-content: space-between; }

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.fade-in {
    animation: fadeIn 0.3s ease-in-out;
}

.slide-up {
    animation: slideUp 0.3s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .profileContainer {
        padding: 0.5rem;
    }

    .communityGrid {
        gap: 0.5rem;
    }

    .card-common {
        padding: 0.75rem;
    }

    .formSection {
        padding: 0.75rem;
    }
}

@media (min-width: 769px) {
    .communityGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Add these new CSS classes for card hover effects */
.profile-card, .content-card, .nav-card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: var(--shadow-md);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-card:hover, .content-card:hover, .nav-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
}

.profile-avatar:hover {
    transform: scale(1.05);
}

.button-icon:hover {
    transform: scale(1.1);
    background: #ff1a1a;
}

/* Update/add these CSS classes */
.profile-card {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 2.5rem 2rem;
    box-shadow: var(--shadow-md);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.profile-card::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.8;
}

.profile-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
}

.profile-avatar {
    transition: transform 0.3s ease;
    backface-visibility: hidden;
}

.profile-avatar:hover {
    transform: scale(1.05);
}

.button-icon {
    opacity: 0.9;
    transition: all 0.2s ease !important;
}

.button-icon:hover {
    transform: scale(1.1) !important;
    opacity: 1;
    background: #2D3748 !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}

/* Common card styles for all cards */
.card-common {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 1rem;
    margin-bottom: 0.5rem;
    box-shadow: var(--shadow-md);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.card-common::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.8;
    z-index: 1;
}

.card-common:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
}

/* Ensure all content is above gradient */
.card-common > * {
    position: relative;
    z-index: 2;
}

/* Apply to all card types */
.profile-card,
.content-card,
.nav-card {
    composes: card-common;
}

/* Specific adjustments for nav-card */
.nav-card {
    padding: 1.5rem;
}

/* Specific adjustments for content-card */
.content-card {
    width: 100%;
    max-width: 600px;
}

/* Add these styles for the nav buttons */
.nav-button {
    position: relative;
    transition: all 0.2s ease;
}

.nav-button:hover {
    color: var(--primary-color);
}

.nav-button::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    background: var(--primary-color);
    transition: all 0.2s ease;
    transform: translateX(-50%);
}

.nav-button:hover::after {
    width: 100%;
}

/* Remove the nav-card styles since we're no longer using them */
.nav-card {
    display: none;
}

/* Profile card specific adjustments */
.profile-card {
    position: relative;
}

.profile-card::before {
    z-index: 1;
}

.profile-card > * {
    position: relative;
    z-index: 2;
}

/* Content card specific adjustments */
.content-card {
    position: relative;
}

.content-card::before {
    z-index: 1;
}

.content-card > * {
    position: relative;
    z-index: 2;
}

/* Update cursor styles */
button, 
.nav-button, 
.button-icon,
.nav-item {
    cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    cursor: text;
}

/* All other elements should use default cursor */
* {
    cursor: default;
}

/* Update specific elements that should have pointer cursor */
.profile-avatar:hover,
.card-common:hover,
.nav-button:hover,
.button-icon:hover {
    cursor: pointer;
}

/* Ensure form elements maintain text cursor */
.deleteConfirmInput,
.input,
input,
textarea {
    cursor: text !important;
}

/* First, set default cursor for everything */
* {
    cursor: default;
}

/* Set pointer cursor for clickable elements */
button,
.nav-button,
.button-icon,
.nav-item,
a,
[role="button"],
.clickable {
    cursor: pointer !important;
}

/* Set text cursor ONLY for text input elements */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="number"],
textarea,
[contenteditable="true"] {
    cursor: text !important;
}

/* Ensure specific elements that shouldn't have text cursor */
.profile-name,
.profile-role,
.profile-church,
.nav-label,
.section-title,
p,
h1, h2, h3, h4, h5, h6,
span,
div {
    cursor: default !important;
}

/* Ensure hover states maintain correct cursor */
.profile-avatar:hover,
.button-icon:hover,
.nav-button:hover {
    cursor: pointer !important;
}

/* Remove any inherited text cursors */
.card-common,
.profile-card,
.content-card {
    cursor: default !important;
}

/* Reset all cursors to default first */
* {
    cursor: default !important;
}

/* Only allow text cursor on actual input elements */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="number"],
textarea,
[contenteditable="true"] {
    cursor: text !important;
}

/* Set pointer cursor for interactive elements */
button,
.nav-button,
.button-icon,
.nav-item,
a,
[role="button"],
.clickable,
label,
select,
input[type="submit"],
input[type="button"],
input[type="checkbox"],
input[type="radio"] {
    cursor: pointer !important;
}

/* Explicitly set default cursor for text elements */
p,
h1, h2, h3, h4, h5, h6,
span,
div,
.profile-name,
.profile-role,
.profile-church,
.nav-label,
.section-title,
.card-title,
.card-text,
.modal-title,
.modal-text {
    cursor: default !important;
    user-select: none;
}

/* Override any potential hover states */
*:hover {
    cursor: inherit;
}

/* Maintain pointer cursor for interactive elements on hover */
button:hover,
.nav-button:hover,
.button-icon:hover,
.nav-item:hover,
a:hover,
[role="button"]:hover,
.clickable:hover {
    cursor: pointer !important;
}

/* Ensure text remains selectable in specific areas */
.selectable-text {
    user-select: text;
    cursor: default !important;
}

/* Update theme colors */
:root {
    --primary-color: #FF0000;
    --primary-hover: #E60000;
    --primary-light: rgba(255, 0, 0, 0.1);
}

/* Update card gradients */
.card-common::before {
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

/* Add these styles for the toggle switch */
.toggle-switch {
  position: relative;
  width: 50px;
  height: 26px;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-label:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle-input:checked + .toggle-label {
  background-color: var(--primary-color);
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(24px);
}

/* Optional: Add some background elements for the glass effect to be more visible */
.app-layout::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(248, 249, 250, 0.05) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(233, 236, 239, 0.05) 0%, transparent 50%);
  z-index: -1;
}

/* App Footer */
.app-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  margin-top: auto;
}

.footer-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: transparent;
  color: #666666;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.footer-button:hover {
  background: rgba(0, 0, 0, 0.05);
  color: var(--primary-color);
}

.footer-button i {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .app-footer {
    padding: 0.75rem;
  }
  
  .footer-button {
    padding: 0.5rem;
  }
  
  .footer-button span {
    display: none;
  }
}