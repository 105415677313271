@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap');

:root {
    --primary-color: #be301e;
    --secondary-color: #ab495c;
    --background-color: #f8f4e3;
    --text-color: #4a4a4a;
    --highlight-background: #faf0f0;
    --accent-color: #c0a570;
    --button-hover-color: #730018;
    --selection-color: #520014;
    --spacing-unit: 16px;
    --button-text-color: #ffffff;

    --dark-background-color: #251f1f;
    --dark-text-color: #e6e0d5;
    --dark-highlight-background: #3e3737;
    --dark-border-color: #5e5656;

    --dark-primary-color: #d4af37;
    /* Gold for dark mode */
    --dark-secondary-color: #c0a570;
    --dark-button-hover-color: #b29600;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-color);
    transition: background-color 0.3s;
}

.dark-mode .overlay {
    background-color: var(--dark-background-color);
}

.paypal-container {
    background-color: #ffffff;
    border-radius: 16px;
    text-align: center;
    padding: 20px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s, color 0.3s;
}

.dark-mode .paypal-container {
    background-color: var(--dark-background-color);
}

.header {
    background-color: var(--background-color);
    padding: 10px 0;
    border-radius: 16px 16px 0 0;
    color: var(--text-color);
    font-family: 'Cardo', serif;
    font-weight: bold;
}

.dark-mode .header {
    background-color: var(--dark-background-color);
    color: var(--dark-text-color);
}

.paypal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-container {
    margin-bottom: var(--spacing-unit);
    color: var(--text-color);
    text-align: center;
}

.dark-mode .info-container {
    color: var(--dark-text-color);
}

.highlight {
    color: var(--accent-color);
}

.info-button,
.close-button,
.nav-button {
    background: linear-gradient(145deg, var(--primary-color), #900025);
    color: var(--button-text-color);
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    font-family: 'Cardo', serif;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: inset 2px 2px 5px rgba(255, 255, 255, 0.3),
        inset -2px -2px 5px rgba(0, 0, 0, 0.2),
        5px 5px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, transform 0.1s ease-in-out, box-shadow 0.3s;
    min-width: 120px;
}

.dark-mode .info-button,
.dark-mode .close-button,
.dark-mode .nav-button {
    background: linear-gradient(145deg, #b08000, #d4af37);
}

.info-button:hover,
.close-button:hover,
.nav-button:hover {
    background-color: var(--button-hover-color);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.dark-mode .info-button:hover,
.dark-mode .close-button:hover,
.dark-mode .nav-button:hover {
    background-color: var(--dark-button-hover-color);
}

.overlay-info {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    color: var(--text-color);
    padding: var(--spacing-unit);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.dark-mode .overlay-info {
    background-color: var(--dark-background-color);
    color: var(--dark-text-color);
}

.paypal-button-container {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-unit);
}

.divider {
    margin-top: var(--spacing-unit);
    margin-bottom: var(--spacing-unit);
    background-color: var(--secondary-color);
    /* height: '2px'; */
    /* width: 100%; */
}

.dark-mode .divider {
    margin-top: var(--spacing-unit);
    background-color: var(--dark-secondary-color);
}

.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-unit);
}

.dropdown {
    width: 200px;
    background-color: var(--highlight-background);
    color: var(--text-color);
    border-radius: 5px;
    margin-bottom: var(--spacing-unit);
    transition: background-color 0.3s, color 0.3s;
}

.dark-mode .dropdown {
    background-color: var(--dark-highlight-background);
    color: var(--dark-text-color);
}